.main-footer {
    background: var(--mainDark);
    color: var(--mainWhite);
}

.footer-bottom {
    color: var(--mainGrey);
}


i {
    font-size: 2.8rem;
}

.fa-facebook-f {
    color: #466abe;
}

.fa-youtube {
    color: tomato;
}

i:hover {
    color: var(--mainBlue);
    cursor: pointer;
}

hr {
    background: lightsteelblue;
}

.black {
    background-color: rgba(33, 33, 38, 0.99)
}

.sponsor_img {
    max-width: 80px;
    max-height: 65px;
}

@media (max-width: 1000px) {
    .sponsor_img {
        width: 50px;
        height: 40px;
    }
}

.small-text {
    font-size: small;
}

.eqi-container {
    display: flex;
    justify-content: space-between;
}
