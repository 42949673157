.photos-img-container {
    height: 250px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    border-radius: 5px;
}

.photos-img {
    position: absolute;
    margin: auto;
    width: auto;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    transition: all 0.3s;
    transform: scale(1);
    border-radius: 5px;
}

.photos-img:hover {
    transform: scale(1.05);
}

@media (max-width: 768px) {
    .photos-img-container {
        height: 150px;
    }
}

@media (max-width: 992px) {
    .photos-img-container {
        height: 200px;
    }
}


@media (max-width: 576px) {
    .photos-img-container {
        position: relative;
        height: fit-content !important;
        width: 100%;
    }

    .photos-img {
        position: relative;
        height: fit-content !important;
        width: 100%;
    }
}

