.image-box-exhibition {
    position: relative;
    margin: auto;
    overflow: hidden;
    cursor: pointer;
    max-height: 300px;
}

.image-box-exhibition img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
    transform: scale(1);
}

.image-box-exhibition:hover img {
    transform: scale(1.05);
}
