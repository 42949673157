.contact-information {
    min-height: 800px;
    margin-top: 30px !important;
}

.image-box-contact {
    margin: auto;
    overflow: hidden;
    max-height: 250px !important;
    max-width: 200px !important;
    border-radius: 5px;
}

.image-box-contact img {
    max-width: 100%;
    max-height: 270px;
    display: block;
}

.contact-jumbotron {
    margin: auto;
    color: white;
    background: var(--mainDark);
}