.custom-carousel {
    width: 100%; /*width you want*/
    height: 350px; /*height you want*/
    overflow: hidden;
}

.custom-carousel img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.my-carousel {
    border-radius: 5px;
    overflow: hidden;
}
