.image-box-festival {
    position: relative;
    margin: auto;
    overflow: hidden;
    cursor: pointer;
    height: 230px;
}

.image-box-festival img {
    max-width: 100%;
    transition: all 0.3s;
    display: block;
    max-height: 350px;
    width: 100%;
    transform: scale(1);
}

.image-box-festival:hover img {
    transform: scale(1.1);
}

.homepage-jumbotron {
    background-image: url('/images/homepage/homepage1.jpg');
    background-size: cover;
    color: white;
}

.image-box-carousel {
    overflow: hidden;
}

.image-box-carousel img {
    display: block;
    overflow: hidden;
    height: 320px !important;
}

@media (max-width: 992px) {
    .image-box-carousel img {
        height: 250px !important;
    }
}