:root {
    --mainDark: #343a40;
    --mainGrey: rgba(109, 109, 109, 1);
    --mainLightGrey: rgba(172, 172, 172, 1);
    --mainWhite: #fff;
    --mainBlue: #007bff;
}


.spacer {
    height: 56px;
}
