.logo_picture {
    max-height: 70px;
    position: absolute;
    top: 0;
    left: 25px;
    cursor: pointer;
}

.logo_picture:hover {
    max-height: 80px;
    top: -5px;
    left: 20px;
}

@media (max-width: 992px) {
    .logo_picture {
        max-height: 40px;
        position: static;
    }

    .logo_picture:hover {
        max-height: 40px;
        position: static;
    }
}

.dropdown-item:hover {
    background-color: rgba(127, 58, 82, 0.84);
}

.nav-colors {
    background: #783a4b;
}