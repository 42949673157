.modal-img {
    max-height: 91vh;
    max-width: 95vw;
}

.close-btn {
    position: absolute;
    right: 5px;
    background: transparent;
    padding: 0;
    border: none;
    transform: scale(1.3);
}

.close-btn i {
    color: white;
}

.modal-dialog {
    max-width: max-content;
    max-height: max-content;
}

.modal-content {
    border: 0 !important;
}
