@media (max-width: 768px) {
    .custom-table {
        font-size: 0.7rem;
    }

    .custom-table h4 {
        font-size: 1.0rem;
    }

    .custom-table h5 {
        font-size: 0.8rem;
    }
}

.fa-location-arrow {
    color: #783a4b;
}

.lang-button {
    position: relative;
    right: 0;
}

.button-header {
    height: 20px;
}

.title-container {
}
